"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.applyMiddleware = exports.middlewares = exports.withAmplitudeActionsFix = exports.withConciousPartnerCookies = exports.withTiktokPixelCookies = void 0;
var _1 = require(".");
/**
 * Operations we want to apply for all analytics events should be added as middleware
 * to allow a standard `window.analytics.track` call to handle custom logic such as adding
 * additional ids, enhancing events with special properties, filtering out sensitive data, etc
 * */
var withTiktokPixelCookies = function (_a) {
    var payload = _a.payload, next = _a.next;
    var type = payload.type();
    var ttpCookie = (0, _1.getCookie)('_ttp');
    var ttclidCookie = (0, _1.getCookie)('ttclid');
    if (type === 'track' || type === 'page') {
        payload.obj.properties = __assign(__assign(__assign({}, payload.obj.properties), (ttpCookie && { ttp: ttpCookie })), (ttclidCookie && { ttclid: ttclidCookie }));
    }
    next(payload);
};
exports.withTiktokPixelCookies = withTiktokPixelCookies;
var withConciousPartnerCookies = function (_a) {
    var payload = _a.payload, next = _a.next;
    var type = payload.type();
    var cpclidCookie = (0, _1.getCookie)('cpclid');
    if (type === 'track' || type === 'page') {
        payload.obj.properties = __assign(__assign({}, payload.obj.properties), (cpclidCookie && { cpclid: cpclidCookie }));
    }
    next(payload);
};
exports.withConciousPartnerCookies = withConciousPartnerCookies;
// This fixes a bug in Segment Amplitude integration.
// https://jouzen.atlassian.net/browse/MMBER-4110
var withAmplitudeActionsFix = function (_a) {
    var payload = _a.payload, next = _a.next;
    var integrations = payload.obj.integrations;
    if (integrations) {
        var amplitudeEnabled = Boolean(integrations['Actions Amplitude']);
        if (amplitudeEnabled) {
            integrations['Amplitude (Actions)'] = true;
        }
    }
    next(payload);
};
exports.withAmplitudeActionsFix = withAmplitudeActionsFix;
// Order matters here
exports.middlewares = [
    exports.withTiktokPixelCookies,
    exports.withConciousPartnerCookies,
    exports.withAmplitudeActionsFix,
];
function applyMiddleware() {
    var _a;
    exports.middlewares.forEach((_a = window.analytics) === null || _a === void 0 ? void 0 : _a.addSourceMiddleware);
}
exports.applyMiddleware = applyMiddleware;
