"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var breakpoint = function (width) { return "@media (min-width: ".concat(width, "px)"); };
var theme = {
    breakpoints: {
        md: breakpoint(600),
        lg: breakpoint(1024),
    },
    colors: {
        ensoBlue: '#A2D3E8',
        helsinkiBlue: '#2F4A73',
        helsinkiBlueDark: '#101926',
        lightGray: '#E5E7EB',
        white: '#FFF',
    },
    fontFamily: {
        sans: 'AkkuratLL, ui-sans-serif, sans-serif',
    },
};
exports.default = theme;
