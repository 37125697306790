"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
var defaultPreferencesDialogTemplate = {
    headings: [
        {
            key: 'allow',
            name: 'default_cookie_allow',
        },
        {
            key: 'category',
            name: 'default_cookie_category',
        },
    ],
    categories: [
        {
            key: 'functional',
            name: 'cookie_category_functional',
            description: 'cookie_description_functional',
            example: 'cookie_example_functional',
            types: [
                'CRM',
                'Custom',
                'Customer Success',
                'Deep Linking',
                'Helpdesk',
                'Livechat',
                'Performance Monitoring',
                'Personalization',
                'SMS & Push Notifications',
                'Security & Fraud',
            ],
        },
        {
            key: 'marketing',
            name: 'cookie_category_marketing',
            description: 'cookie_description_marketing',
            example: 'cookie_example_marketing',
            types: [
                'A/B Testing',
                'Analytics',
                'Attribution',
                'Braze Web Mode (Actions)',
                'Custom',
                'Email Marketing',
                'Enrichment',
                'Heatmaps & Recordings',
                'Other', // TODO: remove this when Segment bug is fixed https://segment.zendesk.com/hc/en-us/requests/517450
                'Raw Data',
                'Realtime Dashboards',
                'Referrals',
                'Surveys',
                'Video',
                'Webhooks',
            ],
        },
        {
            key: 'advertising',
            name: 'cookie_category_advertising',
            description: 'cookie_description_advertising',
            example: 'cookie_example_advertising',
            types: ['Advertising', 'Custom', 'Tag Managers'],
        },
    ],
};
exports.default = defaultPreferencesDialogTemplate;
