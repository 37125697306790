"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.srOnly = void 0;
var react_1 = require("@emotion/react");
exports.srOnly = (0, react_1.css)({
    borderWidth: 0,
    clip: 'rect(0, 0, 0, 0)',
    height: 1,
    width: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    whiteSpace: 'nowrap',
});
