"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.init = void 0;
var jsx_runtime_1 = require("@emotion/react/jsx-runtime");
var react_dom_1 = require("react-dom");
var index_1 = require("./index");
var init = function () {
    if (!window.OURA_CONSENT_MANAGER.segmentKey) {
        throw new Error('window.OURA_CONSENT_MANAGER.segmentKey must be set before loading the Consent Manager.');
    }
    (0, react_dom_1.render)((0, jsx_runtime_1.jsx)(index_1.OuraConsentManager, __assign({}, window.OURA_CONSENT_MANAGER)), document.querySelector('#oura-consent-manager'));
};
exports.init = init;
(0, exports.init)();
